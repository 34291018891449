.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: xx-small;
  padding-left: 100px;
  padding-right: 110px;
}

.btnPrimaryPrevious {
  background-color: #ffcb05;
  border: 2px solid #103984;
  border-radius: 30px;
  box-shadow: #103984 -4px 4px 0px 0px;
  color: #103984;
  cursor: pointer;
  font-weight: 600;
  font-size: 10px;
  padding: 0 10px;
  line-height: 30px;
  text-align: end;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btnPrimaryPrevious:hover {
  background-color: #fff;
}

.btnPrimaryPrevious:active {
  box-shadow: #103984 -4px 4px 0px 0px;
  transform: translate(2px, 2px);
}

.btnPrimaryNext {
  background-color: #ffcb05;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  font-weight: 600;
  font-size: 10px;
  padding: 0 10px;
  line-height: 30px;
  text-align: end;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btnPrimaryNext:hover {
  background-color: #fff;
}

.btnPrimaryNext:active {
  box-shadow: #422800 4px 4px 0 0;
  transform: translate(2px, 2px);
}

.li {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 1em;
  font-size: 18px;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}

.buttonPages {
  background-color: #ffcb05;
  border: 2px solid #103984;
  border-radius: 30px;
  box-shadow: #103984 0px 4px 0px 0px;
  color: #103984;
  cursor: pointer;
  font-weight: 600;
  font-size: 10px;
  padding: 0 15px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 40px;
}

.buttonPages:hover {
  background-color: #fff;
}

.buttonPages:active {
  box-shadow: #103984 0px 4px 0px 0px;
  transform: translate(2px, 2px);
}

.ul {
  text-align: center;
  text-decoration: none;
  border-radius: 25px;
  background: #fff;
  font-size: 13px;
  box-sizing: border-box;
  transition: 0.5s;
  outline: none;
}

.h4 {
  text-align: center;
  border: solid 2px #1d2c5e;
  border-radius: 25px;
  background: #fff;
  font-size: 13px;
  box-sizing: border-box;
  transition: 0.5s;
  outline: none;
  width: 50px;
  line-height: 1px;
}
