.containerCreate {
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  height: 87.6vh;
  width: 214vh;
  background-image: url("../../assets/img/pokemon_picachu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.form {
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  box-sizing: border-box;
  padding: 30px 25px;
  background-color: #f2f2f2;
  border-radius: 40px;
  margin-top: 10px;
  margin-left: 150px;
  width: 500px;
}

.label {
  background: #103984;
  color: #fff;
  display: inline-block;
  position: relative;
  font-size: 15px;
  box-sizing: border-box;
  transition: 0.5s;
  border: none;
  outline: none;
  padding: 5px 15px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  margin: 5px;
  width: 100px;
}

.input {
  background: #fff;
  display: inline-block;
  position: relative;
  font-size: 15px;
  box-sizing: border-box;
  transition: 0.5s;
  border: none;
  outline: none;
  padding: 5px 15px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin: 10px;
  width: 300px;
}

input:required:invalid {
  border-color: lightcoral;
}

.Submmit {
  background: #103984;
  color: #fff;
  display: inline-block;
  position: relative;
  font-size: 20px;
  box-sizing: border-box;
  transition: 0.5s;
  border: none;
  outline: none;
  padding: 10px 30px;
  border-radius: 25px;
  cursor: pointer;
}
.Submmit:hover {
  background: red;
  color: black;
}
