.header {
  height: 85%;
  width: 94%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.image {
  width: 75%;
}

.btnPrimary {
  background-color: #ffcb05;
  border: 2px solid #103984;
  border-radius: 30px;
  box-shadow: #103984 -4px 4px 0px 0px;
  color: #103984;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  padding: 0 17px;
  line-height: 50px;
  text-align: end;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btnPrimary:hover {
  background-color: #fff;
}

.btnPrimary:active {
  box-shadow: #103984 -4px 4px 0px 0px;
  transform: translate(2px, 2px);
}

.h1 {
  color: #103984;
  font-size: 30px;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 0%;
  padding-bottom: 5px;
}

.h5 {
  color: #103984;
  font-size: 15px;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 0%;
  padding-bottom: 70px;
}

.background {
  background-image: url("../../assets/img/pokeballs.jpg");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  padding-top: 50px;
}

.contentButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  z-index: 1000;
  padding-bottom: 170px;
}
