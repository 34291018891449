.details {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 600px;
  border: solid #ffcb05;
  border-width: 10px;
  border-radius: 15px;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-small;
  margin: 10px;
  padding: 10px;
  margin-top: 90px;
  margin-left: 450px;
  margin-bottom: 50px;
}

.btnPrimaryBack {
  background-color: #ffcb05;
  border: 2px solid #103984;
  border-radius: 30px;
  box-shadow: #103984 -4px 4px 0px 0px;
  color: #103984;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  padding: 0 10px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding: 10px;
  width: 100px;
}

.btnPrimaryBack:hover {
  background-color: #fff;
}

.btnPrimaryBack:active {
  box-shadow: #103984 -4px 4px 0px 0px;
  transform: translate(2px, 2px);
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  margin: 20px;
}
