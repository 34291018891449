.firstContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.filterscontainer {
  display: inline-flex;
  justify-content: space-around;
  font-family: Arial, Helvetica, sans-serif;
  font-size: xx-small;
  align-items: center;
  margin-bottom: 10px;
}

.divs {
  padding: 0 5px;
}

.input {
  border: solid 2px #1d2c5e;
  font-size: 15px;
  width: 150px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 5px;
  cursor: pointer;
}

.input2 {
  border: solid 2px #1d2c5e;
  font-size: 15px;
  width: 150px;
  border-radius: 25px;
  padding: 5px;
  cursor: pointer;
}

.btnPrimaryClearFilters {
  background-color: #ffcb05;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  font-weight: 600;
  font-size: 10px;
  padding: 0 10px;
  line-height: 30px;
  text-align: end;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btnPrimaryClearFilters:hover {
  background-color: #fff;
}

.btnPrimaryClearFilters:active {
  box-shadow: #422800 4px 4px 0 0;
  transform: translate(2px, 2px);
}

.label {
  background: #103984;
  color: #fff;
  display: inline-block;
  position: relative;
  font-size: 15px;
  box-sizing: border-box;
  transition: 0.5s;
  border: none;
  outline: none;
  padding: 7px 20px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  margin: 5px;
  width: 150px;
}
