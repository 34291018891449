.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 5em;
}

.card {
  background-color: #ffcb05;
  border: 1px solid black;
  display: inline-block;
  line-height: 10px;
  justify-content: center;
  border-radius: 4%;
  padding: 50px;
  height: 35vh;
  width: 20vh;
  border: 1px solid black;
  align-content: center;
  flex-wrap: wrap;
  gap: 1em;
}

.card:hover {
  transform: rotate(365deg);
  box-shadow: 10px 20px 30px 5px;
  background-color: crimson;
}

.h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  display: block;
  color: black;
}

.p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  display: block;
  color: black;
}

.data {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  color: black;
}

.button {
  border-radius: 3px;
  display: inline-block;
  background-color: crimson;
  font-weight: bold;
  font-size: 20px;
  margin: 5px;
}

.button:hover {
  background: rgba(1, 1, 1, 0.5);
}

.img {
  border-radius: 5px;
  width: 95%;
}
