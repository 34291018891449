.displayCards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5em;
  text-decoration: none;
  flex-wrap: wrap;
}

.divBy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #103984;
  font-size: 15px;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 0%;

}